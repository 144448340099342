








































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UMichSandboxQ2',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      attachments: [] as File[],
      rules: [
        (value: File[]) => {
          // If no file is selected
          if (!value || !value.length) {
            return true;
          }

          // Check file count
          if (value.length > 1) {
            return `You can upload a maximum of 1 image(s).`;
          }

          // Check file sizes
          for (let file of value) {
            if (file.size > 20000000) {
              return `File size must be less than 20 MB.`;
            }
          }

          return true;
        },
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/UMichQ3.png';
    },
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
